import _mapValues from "lodash/mapValues";
import Joi from "joi";

import {SettingsSchemaLive} from "./SettingsSchemaLive"

export class SettingsSchemaPreLiquid extends SettingsSchemaLive {
  
  // Override the subscription schema since it is the only thing different from the live schema
  get subscriptionConfigSchema() {
    return {
      sports: Joi.array()
        .items(Joi.string().valid(...this.options.sportsList))
        .required(),
      sportsMarkets: Joi.object()
        .keys(
          _mapValues(this.options.markets, (marketsForASport) =>
            Joi.array().items(
              Joi.string()
                .valid(...marketsForASport)
                .required()
            )
          )
        )
        .required(),
      minPercentage: Joi.number().min(-100).max(100).required(),
      maxPercentage: Joi.number().min(-100).max(100).required(),
      filterOverTimeMismatch: Joi.boolean().required(),
      excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
    };
  }

  // Default configuration that concerns one arbitrage, i.e. a pair of bookies
  get defaultSubscriptionConfig() {
    return {
      sports: this.options.sportsList,
      sportsMarkets: this.options.markets,
      minPercentage: -3,
      maxPercentage: 3,
      filterOverTimeMismatch: true,
      excludedLeagues: [], // list of strings used to match with league names that will be excluded
    };
  }

  get version() {
    return "2";
  }

}
