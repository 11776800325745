import { makeAutoObservable, runInAction } from "mobx";

import { loadOptions } from "./SettingsOptions";
import { SettingsSchemaLive } from "./SettingsSchemaLive";
import { SettingsSchemaPre } from "./SettingsSchemaPre";
import { SettingsSchemaPreLiquid } from "./SettingsSchemaPreLiquid";
import { SettingsSchemaValuebetsPre } from "./SettingsSchemaValuebetsPre";
import { SettingsStorage } from "./SettingsStorage";
export class SettingsStore {
  _load = true;
  options = {};
  settingsIdx = undefined; // selected settings index
  settingsList = [];
  storage = {};
  type; // live or pre

  constructor(type) {
    this.type = type;
    makeAutoObservable(this);
  }

  get isLive() {
    return this.type === "live";
  }

  get isPre() {
    return this.type === "pre";
  }

  get isValuebetsPre() {
    return this.type === "valuebetsPre";
  }

  async load() {
    if (!this._load) return; // load only once
    this._load = false;
    const options = await loadOptions(this.type);
    runInAction(() => {
      this.options = options;
      this.storage = new SettingsStorage(this, this.type);
    });
    await this.storage.loadSettingsList();
  }

  get settings() {
    return this.settingsList[this.settingsIdx];
  }

  setSettingsById = (settingsId) => {
    const idx = this.settingsList.findIndex(settings => settings._id?.id === settingsId);
    if (idx !== -1) this.setSettingsIdx(idx);
  }

  setSettingsIdx = (settingsIdx) => this.settingsIdx = settingsIdx;

  setSettingsList = (settingsList) => this.settingsList = settingsList;

  // Return the correct settings schema (for live or pre)
  get schema() {
    if (!this.options) return undefined;
    const availableSchemas = { live: SettingsSchemaLive, pre: SettingsSchemaPre, preLiquid: SettingsSchemaPreLiquid, valuebetsPre: SettingsSchemaValuebetsPre };
    const Schema = availableSchemas[this.type];
    return new Schema(this.options);
  }

}
