import _mapValues from "lodash/mapValues";
import Joi from "joi";

import { SettingsSchemaValuebets } from "./SettingsSchemaValuebets";

export class SettingsSchemaValuebetsPre extends SettingsSchemaValuebets {
  get subscriptionConfigSchema() {
    return {
      sports: Joi.array()
        .items(Joi.string().valid(...this.options.sportsList))
        .required(),
      sportsMarkets: Joi.object()
        .keys(
          _mapValues(this.options.markets, (marketsForASport) =>
            Joi.array().items(
              Joi.string()
                .valid(...marketsForASport)
                .required()
            )
          )
        )
        .required(),
      minPercentage: Joi.number().min(-100).max(100).required(),
      maxPercentage: Joi.number().min(-100).max(100).required(),
      excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
      minOdds: Joi.number().min(0).max(10000).required(),
			maxOdds: Joi.number().min(-100).greater(Joi.ref('minOdds')).required(),
    };
  }

  get configurationSchema() {
    const _subscriptionConfigSchema = this.subscriptionConfigSchema;

    return Joi.object({
      bookies: Joi.array()
        .items(Joi.string().valid(...this.options.bookiesList))
        .required(),
      ..._subscriptionConfigSchema,
      sortBy: Joi.string()
        .valid(...this.options.sortings.map((o) => o.type))
        .required(),
      subscriptionConfigs: Joi.object()
        .keys(
          Object.fromEntries(
            this.options.subscriptions.map((subscription) => [subscription, Joi.object(_subscriptionConfigSchema)])
          )
        )
        .required(),
    }).required();
  }

  get settingsSchema() {
    return Joi.object({
      _id: Joi.object({
        email: Joi.string(),
        id: Joi.string().required(),
      }),
      name: Joi.string().max(80).required(),
      type: Joi.string().valid("valuebetsPre").required(),
      defaultConfiguration: Joi.boolean().required(),
      version: Joi.string().max(10).required(),
      configuration: this.configurationSchema,
      addedOn: Joi.string(),
      updatedOn: Joi.string(),
    });
  }

  // Default configuration that concerns one arbitrage, i.e. a pair of bookies
  get defaultSubscriptionConfig() {
    return {
      sports: this.options.sportsList,
      sportsMarkets: this.options.markets,
      minPercentage: 0,
      maxPercentage: 100,
      minOdds: 1,
      maxOdds: 6,
      excludedLeagues: [], // list of strings used to match with league names that will be excluded
    };
  }

  // Default configuration for the arbitrages page
  get defaultConfiguration() {
    return {
      bookies: this.options.bookiesListExtended.filter(bookie => bookie.highlighted).map(bookie => bookie.bookie),
      sortBy: "timeDes",
      ...this.defaultSubscriptionConfig,
      subscriptionConfigs: {},
    };
  }

  get defaultSettings() {
    return {
      name: "Mi configuración",
      defaultConfiguration: false,
      configuration: this.defaultConfiguration,
      version: this.version,
      type: "valuebetsPre",
    };
  }

  get version() {
    return "1";
  }

  // Initialize all configurations per subscriptions with the default values
  // get defaultSubscriptionConfigs() {
  //   return this.subscriptions.reduce((config, subscription) => {
  //     config[subscription] = this.defaultSubscriptionConfig;
  //     return config;
  //   }, {});
  // }
}
