import _mapValues from "lodash/mapValues";
import Joi from "joi";

import { SettingsSchemaLive } from "./SettingsSchemaLive"

export class SettingsSchemaPre extends SettingsSchemaLive {
  
  // Override the subscription schema since it is the only thing different from the live schema
  get subscriptionConfigSchema() {
    return {
      sports: Joi.array()
        .items(Joi.string().valid(...this.options.sportsList))
        .required(),
      sportsMarkets: Joi.object()
        .keys(
          _mapValues(this.options.markets, (marketsForASport) =>
            Joi.array().items(
              Joi.string()
                .valid(...marketsForASport)
                .required()
            )
          )
        )
        .required(),
      minPercentage: Joi.number().min(-100).max(100).required(),
      maxPercentage: Joi.number().min(-100).max(100).required(),
      filterOverTimeMismatch: Joi.boolean().required(),
      excludedLeagues: Joi.array().items(Joi.string().max(80)).required(),
      onlyLiquid: Joi.boolean().required(),
      mode: Joi.string().valid("normal", "freebet").required(),
    };
  }

  // Default configuration that concerns one arbitrage, i.e. a pair of bookies
  get defaultSubscriptionConfig() {
    return {
      sports: this.options.sportsList,
      sportsMarkets: this.options.markets,
      minPercentage: 0,
      maxPercentage: 100,
      filterOverTimeMismatch: true,
      excludedLeagues: [], // list of strings used to match with league names that will be excluded
      onlyLiquid: false,
      mode: 'normal'
    };
  }

  // Default configuration for the arbitrages page
  get defaultConfiguration() {
    return {
      bookies: this.options.bookiesListExtended.filter(bookie => bookie.highlighted).map(bookie => bookie.bookie),
      bookiesReference: ["bet365"],
      sortBy: "profitDes",
      ...this.defaultSubscriptionConfig,
      subscriptionConfigs: {},
      bookiesConfigs: {},
    };
  }

  get defaultSettings() {
    return {
      name: "Mi configuración",
      defaultConfiguration: false,
      configuration: this.defaultConfiguration,
      version: this.version,
      type: "pre",
    };
  }
  
  get version() {
    return "2.2.1";
  }

}
