export const createAuthService = (authRepository) => {
    const login = emailFromInput => authRepository.login(emailFromInput)

    const getUserInfo = () => authRepository.getUserInfo()

    const getAdministrators = () => authRepository.getAdministrators()

    const acceptTerms = (version = "1.0") => authRepository.acceptTerms(version)

    return {
        login,
        getUserInfo,
        getAdministrators,
        acceptTerms
    }
}
