import _compact from "lodash/compact";
import _uniq from "lodash/uniq";

// Sort an array of strings
const getStringsSorted = (arr) => arr.sort((a, b) => a.localeCompare(b));

// Given 2 bookies return the name of the subscription
export const subscriptionName = (bookie1, bookie2) => getStringsSorted([bookie1, bookie2]).join("-");

// Given a configuration, return an array of strings corresponding to the bookie pairs
// to subscribe to on the server
export const getSubscriptions = (configuration) => {
  if (!configuration) return [];
  const bookies = (configuration.bookies || []);
  const bookiesReference = (configuration.bookiesReference || []);

  let subscriptions = bookiesReference.flatMap((bookie1) =>
    bookies.map((bookie2) => bookie1 !== bookie2 && subscriptionName(bookie1, bookie2))
  );
  subscriptions = _compact(subscriptions); // remove cases where bookie1 == bookie2
  subscriptions = _uniq(subscriptions); // remove repeated subscriptions
  return subscriptions;
};

// Given a configuration, return an array of strings corresponding to the bookie pairs
// to subscribe to on the server
export const getSubscriptionsForSocket = (configuration) => {
  if (!configuration) return [];
  const bookies = (configuration.bookies || []).map((bookie) => bookie.split(":").length > 1 ? bookie.split(":")[0] : bookie);
  const bookiesReference = (configuration.bookiesReference || []).map((bookie) => bookie.split(":").length > 1 ? bookie.split(":")[0] : bookie);

  let subscriptions = bookiesReference.flatMap((bookie1) =>
    bookies.map((bookie2) => bookie1 !== bookie2 && subscriptionName(bookie1, bookie2))
  );
  subscriptions = _compact(subscriptions); // remove cases where bookie1 == bookie2
  subscriptions = _uniq(subscriptions); // remove repeated subscriptions
  return subscriptions;
};

// Given a configuration, return an array of strings corresponding to the bookie pairs
// to subscribe to on the server
export const getSubscriptionsForValuebets = (configuration) => {
  if (!configuration) return [];
  const bookies = (configuration.bookies || []);

  return bookies;
};