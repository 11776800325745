export const getBackgroundColorForBookie = (bookie) => {
    const bookies = {
        bet365: 'rgb(31,128,96)',
        bwin: 'rgb(0,0,0)',
        bethard: 'rgb(0,0,0)',
        '888': 'rgb(0,0,0)',
        betway: 'rgb(0,0,0)',
        luckia: 'rgba(231,93,37,.87)',
        default: 'rgba(0,0,0,0.1)'
    }
    return bookies[bookie] || bookies.default;
}

export const getTextColorForBookie = (bookie) => {
    const bookies = {
        bet365: 'rgb(253,229,70)',
        bwin: 'rgb(253,212,60)',
        bethard: 'rgb(253,212,60)',
        betway: 'rgb(255,255,255)',
        luckia: 'rgb(210,210,210)',
        '888': 'rgba(231,93,37,.87)',
        default: 'rgb(255,255,255)'
    }
    return bookies[bookie] ||bookies.default;
}

export const getErrorMessage = (error) => {
    const messages = {
        bookie_already_added: "Bookie ya añadida",
        bookie_not_allowed: "Bookie no permitida",
        bookie_not_present: "Bookie no presente en tu configuración",
        default: error.error
    };
    return messages[error.error] || messages.default;
};
