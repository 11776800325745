export function getUserName(user) {
  const email = user._id ? user._id.email : user;
  return email.split("@")[0].toUpperCase();
}

export function getBalance(action) {
  return `: ${parseFloat(action?.balance?.total).toLocaleString(
    navigator.language || "es",
    {
      style: "currency",
      currency: "EUR",
    },
  )}`
}