export const getBetStatus = (bet) => {
    const status = {
        draw: "#3B82F6",
        won: "#94E5AB",
        lost: "#E44444",
        default: "white",
    };

    if (!('payout' in bet)) return status.default;
    else if (bet.payout === bet.stake) return status.draw;
    else if (bet.payout > bet.stake) return status.won;
    else return status.lost;
}