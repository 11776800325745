import { fetch } from "../utils";

export const createArbsRepository = () => {
  // Get arbitratges for pre match
  // Data structure:
  // {
  //   channels: Joi.array().required(),
  //   minPercentage: Joi.number().min(-100).max(100).required(),
  //   maxPercentage: Joi.number().min(-100).max(100).required(),
  // }
  const getArbsPre = (data) =>
    fetch({
      method: "post",
      path: "arbs/query",
      data,
      headers: { Authorization: localStorage.jwt },
    });

  const getArbsPreLiquid = (data) =>
    fetch({
      method: "post",
      path: "arbs/query/liquid",
      data,
      headers: { Authorization: localStorage.jwt },
    });

  return {
    getArbsPre,
    getArbsPreLiquid,
  };
};
