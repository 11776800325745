import { makeAutoObservable } from "mobx";

import { getSubscriptions, getSubscriptionsForValuebets } from "../../utils/subscriptions";
import { createUserRepository } from "../../repositories/UserRepository/User.repository";

const userRespository = createUserRepository();

// Load list of bookies, etc. from the server
// Example:
// {
//   bookies: [
//     { bookie: "bet365", label: "Bet365", countries: ["ES"] },
//     { bookie: "wanabet", label: "Wanabet", countries: ["ES"] },
//     { bookie: "casumo", label: "Casumo", countries: ["ES"] },
//     { bookie: "paf", label: "PAF/Enracha", countries: ["ES", "EE"] },
//     { bookie: "mrgreen", label: "MrGreen", countries: ["ES"] },
//     { bookie: "leovegas", label: "Leovegas", countries: ["ES"] },
//     { bookie: "unibet", label: "Unibet", countries: ["EE"] },
//     { bookie: "retabet", label: "Retabet", countries: ["ES"] },
//     { bookie: "codere", label: "Codere", countries: ["ES"] },
//     { bookie: "versus", label: "Versus", countries: ["ES"] },
//     { bookie: "winamax", label: "Winamax", countries: ["ES"] },
//     { bookie: "bwin", label: "Bwin", countries: ["ES"] },
//     { bookie: "betsson", label: "Betsson/Ebingo/iJuego", countries: ["ES"] },
//     { bookie: "olybet", label: "OlyBet", countries: ["EE"] },
//     { bookie: "888", label: "888", countries: ["ES"] },
//     { bookie: "pokerstars", label: "Pokerstars", countries: ["ES"] },
//     { bookie: "suertia", label: "Suertia", countries: ["ES"] },
//     { bookie: "1xbet", label: "1xbet", countries: ["ES"] },
//     { bookie: "casinobarcelona", label: "Casino Barcelona/GoldenPark", countries: ["ES"] },
//     { bookie: "betfair", label: "Betfair", countries: ["ES"] },
//     { bookie: "williamhill", label: "WilliamHILL", countries: ["ES"] },
//     { bookie: "efbet", label: "Efbet", countries: ["ES"] },
//     { bookie: "interwetten", label: "Interwetten", countries: ["ES"] },
//     { bookie: "zebet", label: "ZEbet", countries: ["ES"] },
//     { bookie: "luckia", label: "Luckia", countries: ["ES"] },
//     { bookie: "jokerbet", label: "Jokerbet/Paston/Casino Madrid", countries: ["ES"] },
//     { bookie: "bethard", label: "Bethard", countries: ["ES"] },
//     { bookie: "bet777", label: "Bet777", countries: ["ES"] },
//     { bookie: "dafabet", label: "Dafabet", countries: ["ES"] },
//     { bookie: "sisal", label: "Sisal", countries: ["ES"] },
//     { bookie: "marcaapuestas", label: "Marca Apuestas", countries: ["ES"] },
//     { bookie: "sportium", label: "Sportium", countries: ["ES"] },
//     { bookie: "betway", label: "Betway", countries: ["ES"] },
//     { bookie: "coolbet", label: "Coolbet", countries: ["EE"] },
//     { bookie: "optibet", label: "Optibet", countries: ["EE"] },
//     { bookie: "pinnacle", label: "Pinnacle", countries: ["EE"] },
//     { bookie: "daznbet", label: "DAZNBET", countries: ["ES"] },
//     { bookie: "posido", label: "Posido", countries: ["EE"] },
//     { bookie: "fenixbet", label: "Fenixbet", countries: ["EE"] },
//     { bookie: "tonybet", label: "Tonybet", countries: ["ES", "EE"] },
//   ],
//   sports: [
//     { sport: "football", label: "Fútbol" },
//     { sport: "basketball", label: "Baloncesto" },
//     { sport: "tennis", label: "Tenis" },
//     { sport: "hockey", label: "Hockey" },
//     { sport: "baseball", label: "Beisbol" },
//     { sport: "americanFootball", label: "Fútbol Americano" },
//   ],
//   nextPeriodFilteringSports: [
//     { sport: "basketball", label: "Baloncesto" },
//     { sport: "tennis", label: "Tenis" },
//   ],
//   sortings: [
//     { type: "profitAsc", text: "Profit Ascendente" },
//     { type: "profitDes", text: "Profit Descendente" },
//     { type: "timeAsc", text: "Tiempo Ascendente" },
//     { type: "timeDes", text: "Tiempo Descendente" },
//   ],
//   pausedFilterSports: [
//     { sport: "basketball", label: "Baloncesto" },
//     { sport: "tennis", label: "Tenis" },
//   ],
//   markets: {
//     football: ["AH", "CRN", "CS", "DC", "DNB", "EH", "FT", "OU", "OUT", "BTS"],
//     basketball: ["BTS", "EH", "OT", "OU", "OUT", "W"],
//     tennis: ["EH", "TGOU", "W"],
//     hockey: ["AH", "DC", "DNB", "EH", "FT", "OU", "OUT", "W", "BTS"],
//     baseball: ["BTS", "EH", "HOU", "HOUT", "OT", "OU", "OUT", "W", "RACE"],
//     americanFootball: ["BTS", "EH", "HOU", "HOUT", "OT", "OU", "OUT", "W", "RACE"],
//   },
//   marketsTranslation: {
//     AH: "Asian Handicap",
//     CRN: "Corners",
//     CS: "Clean Sheet",
//     DC: "Double Chance",
//     DNB: "Draw No Bet",
//     EH: "European Handicap",
//     FT: "Full Time",
//     OU: "Over/Under",
//     OUT: "Over/Under Team",
//     BTS: "Both To Score",
//     W: "Winner",
//     TGOU: "Total Games Over/Under",
//     RACE: "Race to",
//     HOU: "Hits Over/Under",
//     HOUT: "Hits Over/Under team",
//   },
// }
async function loadServerOptions(type) {
  return await userRespository.loadSettingsOptionsV2(type);
}

export async function loadOptions(type) {
  const serverOptions = await loadServerOptions(type);
  return new SettingsOptions(serverOptions, type);
}

class SettingsOptions {
  bookies;
  sports;
  nextPeriodFilteringSports;
  sortings;
  pausedFilterSports;
  markets;
  marketsTranslation;
  
  
  constructor(serverOptions, type) {
    makeAutoObservable(this);

    this.bookies = serverOptions.bookies;
    this.sports = serverOptions.sports;
    this.nextPeriodFilteringSports = serverOptions.nextPeriodFilteringSports;
    this.sortings = serverOptions.sortings;
    this.pausedFilterSports = serverOptions.pausedFilterSports;
    this.markets = serverOptions.markets;
    this.marketsTranslation = serverOptions.marketsTranslation;
    this.type = type;
  }

  // E.g. ["bet365", "optibet", ...]
  get bookiesList() {
    return Object.values(this.bookies.flatMap(bookie => [bookie, ...(bookie.clones || []).map(clone => ({ ...bookie, bookie: `${bookie.bookie}:${clone.bookie}`, label: clone.label }))])).map(({ bookie }) => bookie);
  }

  get bookiesListExtended() {
    return Object.values(this.bookies.flatMap(bookie => [bookie, ...(bookie.clones || []).map(clone => ({ ...bookie, bookie: `${bookie.bookie}:${clone.bookie}`, label: clone.label }))]))
  }

  // Use this object to transform a bookie name to its human readable label
  get bookieToLabel() {
    return this.bookies.flatMap(bookie => [bookie, ...(bookie.clones || []).map(clone => ({ ...bookie, bookie: `${bookie.bookie}:${clone.bookie}`, label: clone.label }))]).reduce((memo, bookie) => ({ ...memo, [bookie.bookie]: bookie.label }), {});
  }

  // Use this object to transform a bookie name to its full bookie object
  get bookieNameToObject() {
    return this.bookies.reduce((memo, bookie) => ({ ...memo, [bookie.bookie]: bookie }), {});
  }

  // E.g. ["football", "basketball", ...]
  get sportsList() {
    return this.sports.map(({ sport }) => sport);
  }

  // All possible subscriptions
  get subscriptions() {
    return this.type === 'valuebetsPre' ? getSubscriptionsForValuebets({ bookies: this.bookiesList }) : getSubscriptions({ bookies: this.bookiesList, bookiesReference: this.bookiesList });
  }
}
