// Return a string for the duration between two dates, i.e 3d 18h 36m 45s
export const getDuration = (start, end) => {
    const duration = end - start;
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);
  
    // return only when bigger than 0
    if (days > 0) return `${days}d ${hours}h ${minutes}m`;
    if (hours > 0) return `${hours}h ${minutes}m`;
    if (minutes >= 30) return `${minutes}m`;
    if (minutes > 0) return `${minutes}m ${seconds}s`;
    if (seconds > 0) return `${seconds}s`;
  }
export const getColorForChangedArb = (newValue, oldValue) => {
    if (!oldValue) return 'white';
    if (newValue > oldValue) return '#94E5AB';
    else if (newValue < oldValue) return '#ff8484';
    return 'white';
    }
  
export const getURL = bet => {
    const bookies = {
      'casumo': `https://www.casumo.es/sports#event/live/${bet.event.id}`,
      'leovegas': `https://www.leovegas.es/es-es/apuestas-deportivas#event/live/${bet.event.id}`,
      'bet365': `https://www.bet365.es/#/IP/${bet.event.websiteId}`,
      'bwin': `https://sports.bwin.es/es/sports/eventos/${bet.event.id}`,
      'kirolbet': `https://apuestas.kirolbet.es/esp/Sport/Evento/${bet.event.id}`,
      '888': `https://www.888sport.es/en-vivo/mercados_partido/${bet.event.id}-ipe-${bet.event.id}/`,
      'pokerstars': `https://www.pokerstarssports.es/#/inplay/event/${bet.event.id}`,
      'versus': `https://apuestasdeportivas.versus.es/sports/soccer/events/${bet.event.id}`,
      'winamax': `https://www.winamax.es/apuestas-deportivas/match/${bet.event.id}`,
      'betway': `https://betway.es/es/sports/evt/${bet.event.id}`,
      'optibet': `https://www.optibet.ee/en/sport/live/${bet.event.id}`,
    }
  
    return bookies[bet.bookie];
  }

export const getBetNameFormatted = (bet, arb) => {
    const sports = {
      tennis: (bet.periodTypeId ? `${bet.shortName} - ${bet.periodTypeId === 1 ? `SET ${bet.period}` : (bet.game ? `SET ${bet.set} JUEGO ${bet.game}`: `JUEGO ${bet.period}`)} ` : bet.shortName),
      basketball: (bet.periodTypeId ? `${bet.shortName} - ${bet.period} ${bet.periodTypeId === 1 ? 'MITAD' : 'CUARTO'}` : bet.shortName),
      americanFootball: (bet.periodTypeId ? `${bet.shortName} - ${bet.period} ${bet.periodTypeId === 1 ? 'MITAD' : 'PERIODO'}` : bet.shortName),
      baseball: (bet.periodTypeId ? `${bet.shortName} - ${bet.periodTypeId === 1 ? `PRIMEROS ${bet.period} INNINGS` : `INNING ${bet.period}`}` : bet.shortName),
      football: (bet.periodTypeId ? `${bet.shortName} - ${bet.period}era PARTE` : bet.shortName),
      hockey: (bet.periodTypeId ? `${bet.shortName} - PERIODO ${bet.period}` : bet.shortName),
    }
  
    return sports[arb.match?.sport?.name] || bet.shortName;
  }