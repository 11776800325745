import { fetch } from "../utils";

export const createValuebetsRepository = () => {
  const getValuebetsPre = (data) =>
    fetch({
      method: "post",
      path: "valuebets/query",
      data,
      headers: { Authorization: localStorage.jwt },
    });

  return {
    getValuebetsPre,
  };
};
