import football from "../static/soccer-ball-with-pentagons-svgrepo-com.png";
import tennisBall from "../static/tennis-ball-svgrepo-com.png";
import basketball from "../static/ball-of-basketball-svgrepo-com.png";
import hockey from "../static/hockey-puck.png";
import baseball from "../static/baseball.png";
import americanFootball from "../static/american-football-pngrepo-com.png";
import footballGoal from "../static/goal.png";

export const getSportIcon = (sportId) => {
    const sports = {
        football: football,
        tennis: tennisBall,
        basketball: basketball,
        hockey: hockey,
        baseball: baseball,
        americanFootball: americanFootball,
    };

    return sports[sportId];
};

export const getGoalIcon = (sportId) => {
    const sports = {
        football: footballGoal,
        tennis: footballGoal,
        basketball: footballGoal,
        hockey: footballGoal,
        baseball: footballGoal,
        americanFootball: footballGoal,
    };

    return sports[sportId];
};

export const getSportIconColor = (sportId) => {
    const sports = {
        football: 'invert(100%) sepia(21%) saturate(0%) hue-rotate(4deg) brightness(108%) contrast(101%)',
        tennis: 'invert(54%) sepia(86%) saturate(340%) hue-rotate(75deg) brightness(90%) contrast(86%)',
        basketball: 'invert(75%) sepia(66%) saturate(3822%) hue-rotate(355deg) brightness(93%) contrast(82%)',
        hockey: 'invert(77%) sepia(93%) saturate(1022%) hue-rotate(356deg) brightness(108%) contrast(104%)',
        baseball: 'invert(72%) sepia(26%) saturate(731%) hue-rotate(347deg) brightness(88%) contrast(79%)',
    };

    return sports[sportId];
};

export const getScore = (sportId, score = {}) => {
    const sports = {
        1: () => [score.home, score.away].join(" - "),
        18: () => score.global,
        17: () => score.global,
        16: () => score.global,
        13: () => score.home.map((sc, idx) => [sc, score.away[idx]].join('-')).join(' '),
    };

    return sports[sportId] ? sports[sportId]() : "";
};

export const getTiming = (sportId, eventTime) => {
    const sports = {
        1: () => `${eventTime.timeMinute}'`,
        18: () => eventTime.timeMinute && eventTime.timeSeconds &&
            `${[eventTime.timeMinute, eventTime.timeSeconds]
                .map((digits) =>
                    digits.toLocaleString("en-US", { minimumIntegerDigits: 2 })
                )
                .join(":")}`,
    };

    return sports[sportId] ? sports[sportId]() : "";
};

export const getPeriod = (sportId, time, period) => {
    const sports = {
        1: () => (time.timeMinute === 0 ? "H1" : "H2"),
        18: () => period.current,
    };

    return sports[sportId] ? sports[sportId]() : "";
};

export const getStatusInformation = event => {
    const sports = {
      football: () => {
          if (event?.status?.paused) return 'Parado'
          return undefined
      },
      tennis: () => {
        if (event?.status?.paused) return 'Parado'
        return undefined
      },
      hockey: () => {
        if (event?.status?.paused) return 'Parado'
        return undefined
      },
      americanFootball: () => {
        if (event?.status?.paused) return 'Parado'
        return undefined
      },
      basketball: () => {
        if (event?.status?.extra?.isInTimeout) return 'Time Out'
        else if (event?.status?.extra?.isAtQuarterStartCheck) return 'Comienzo de cuarto'
        else if (event?.status?.extra?.isFreeThrowsFault) return 'Tiros Libres'
        else if (event?.status?.extra?.isFault) return 'Falta'
        else if (event?.status?.extra?.isHalfTime) return 'Half Time'
        return undefined
      }
    }
    return sports[event.sport?.name] ? sports[event.sport?.name]() : undefined
  }

  export const getScoreForArbs = event => {
    const sports = {
      football: () => `${event?.score?.home}-${event?.score?.away}`,
      basketball: () => `${event?.score?.home}-${event?.score?.away}`,
      hockey: () => `${event?.score?.home}-${event?.score?.away}`,
      baseball: () =>  `${event?.score?.home}-${event?.score?.away}`,
      americanFootball: () => `${event?.score?.home}-${event?.score?.away}`,
      tennis: () => {
        if (event?.score?.homeArray && event?.score?.awayArray) {
          return event.score.homeArray.map((sc, idx) => `${sc}-${event.score.awayArray[idx]}`).join(' | ');
        } else if (event?.score?.home && event?.score?.away) {
          return `${event.score.home}-${event.score.away}`;
        }
        return '0-0';
      },
    }
    return sports[event.sport?.name] ? sports[event.sport?.name]() : undefined
  }

  export const getSubScoreForArbs = event => {
    const sports = {
      tennis: () => event?.score?.currentGameScore || '0-0',
      football: () => {
        const ticking = event.time.ticking;
        const time = event.time.timeMinute;
        const lastTimestamp = new Date(event.time.timestamp);
        const currentTime = new Date();
        const difference = currentTime - lastTimestamp;
        const resolutionTime = difference / 1000 / 60;
        const currentMinutes = ticking ? time + resolutionTime : time;

        return `${Math.round(currentMinutes)}'`;
      },
      basketball: () => {
        const time = `${[(event.time.timeMinute || 0), (event.time.timeSeconds || 0)]
            .map((digits) =>
                digits.toLocaleString("en-US", { minimumIntegerDigits: 2 })
            )
            .join(":")}`
        return `${time} | ${event.period.current}`
    },
       baseball: () => event.period.current,
       hockey: () => {
        const time = `${[(event.time.timeMinute || 0), (event.time.timeSeconds || 0)]
            .map((digits) =>
                digits.toLocaleString("en-US", { minimumIntegerDigits: 2 })
            )
            .join(":")}`
        return `${time} | ${event.period.current}`
    }
    }
    return sports[event.sport?.name] ? sports[event.sport?.name]() : undefined
  }

  export const checkIfScoreChanged = event => {
    const sports = {
      football: () => (event?.score?.home !== 0 || event?.score?.away !== 0) && event.sport?.id === 1 && new Date() - new Date(event.score?.updatedAt) < 3 * 60 * 1000      
    }

    return sports[event.sport?.name] ? sports[event.sport?.name]() : undefined
  }
  
export const getBetStatusInformation = ({ match, status }) => {
  const sports = {
    tennis: () => {
      if (status?.extra?.isNextGame) return 'Próximo Juego'
      else if (status?.extra?.isNextSet) return 'Próximo Set'
      return undefined
    },
    basketball: () => {
      if (status?.extra?.isNextQuarter) return 'Próximo Cuarto'
      else if (status?.extra?.isNextHalf) return 'Próximo Parte'
      return undefined
    }
  }
  return sports[match.sport?.name] ? sports[match.sport?.name]() : undefined
}