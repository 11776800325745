import * as Yup from 'yup';

export const signInForm = Yup.object().shape({
  email: Yup.string().required(),
});

export const createAccountForm = Yup.object({
  email: Yup.string().email().required(),
  name: Yup.string().required(),
  surname: Yup.string().required(),
  terms: Yup.bool().oneOf([true], 'Field must be checked'),
});

export const creditForm = Yup.object({
  amount: Yup.number().required(),
  rate: Yup.number().required(),
  reference: Yup.string(),
  method: Yup.string().required(),
});

export const bookieForm = Yup.object({
  username: Yup.string().required(),
  password: Yup.string().required(),
  enable: Yup.bool().required(),
  date: Yup.date(),
});

export const editBookieForm = Yup.object({
password: Yup.string().required(),
enable: Yup.bool().required(),
date: Yup.date(),
});