import { fetch } from '../utils';

export const createEventsRepository = () => {

    const getEvents = () => fetch({
        method: 'get',
        path: 'events/simple',
        headers: {
            Authorization: localStorage.jwt
        },
    });

    const captureSnapshot = (id, arb) => fetch({
        method: 'post',
        path: `events/${id}/snapshot`,
        data: arb,
        headers: {
            Authorization: localStorage.jwt
        },
    });

    const getAlternativeOdds = (id, arb) => fetch({
        method: 'post',
        path: `events/${id}/odds`,
        data: arb,
        headers: {
            Authorization: localStorage.jwt
        },
    });

    const getUpcomingEvents = () => fetch({
        method: 'get',
        path: `events/upcoming`,
        headers: {
            Authorization: localStorage.jwt
        },
    });

    const sendReport = ({ type, data }) => {
        const { arb, bookie, comment, error, startedOn, files = [] } = data;
        const formData = new FormData();

        formData.append("type", type)
        if (type === 'arb') formData.append("arb", JSON.stringify(arb));
        formData.append("data", JSON.stringify({ bookie, error, comment }));
        formData.append("startedOn", startedOn.toISOString());
        files.forEach(file => formData.append("files", file))
        return fetch({
            method: 'post',
            path: `report`,
            data: formData,
            headers: {
                Authorization: localStorage.jwt,
                'content-type': 'multipart/form-data',
            },
        });
    }

    const getReports = () => fetch({
        method: 'get',
        path: `report/list`,
        headers: {
            Authorization: localStorage.jwt
        },
    });

    return {
        getEvents,
        captureSnapshot,
        getAlternativeOdds,
        sendReport,
        getUpcomingEvents,
        getReports
    }
}
