export const createEventsService = (eventsRepository) => {

    const getEvents = () => eventsRepository.getEvents()
    const captureSnapshot = (id, arb) => eventsRepository.captureSnapshot(id, arb)
    const getAlternativeOdds = (id, arb) => eventsRepository.getAlternativeOdds(id, arb)
    const sendReport = (report) => eventsRepository.sendReport(report)
    const getUpcomingEvents = () => eventsRepository.getUpcomingEvents()
    const getReports = () => eventsRepository.getReports()

    return {
        getEvents,
        captureSnapshot,
        getAlternativeOdds,
        sendReport,
        getUpcomingEvents,
        getReports
    }
}
