import { runInAction } from "mobx";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import Joi from "joi";

import { createUserRepository } from "../../repositories/UserRepository/User.repository";

const userRespository = createUserRepository();

// Class to save and read settings to the server
export class SettingsStorage {
  settingsStore;
  type;

  constructor(settingsStore, type) {
    this.settingsStore = settingsStore;
    this.type = type;
    this.version = settingsStore.schema.version;
  }

  _areSettingsValid = (settings) => {
    try {
      // https://joi.dev/api/?v=17.6.1#assertvalue-schema-message-options
      Joi.assert(settings, this.settingsStore.schema.settingsSchema);
      return true;
      // https://joi.dev/api/?v=17.6.1#errors
    } catch (validationError) {
      console.error(validationError);
      return false;
    }
  };

  // Save settings to the server (local state is not updated)
  // If settings are not valid, throw an error
  _saveSettingsToServer = async (newSettings) => {
    if (!this._areSettingsValid(newSettings)) throw new Error("Settings not valid");
    const settingsId = newSettings._id?.id;
    const payloadSettings = _pick(newSettings, ["name", "defaultConfiguration", "configuration"]);
    const settingsWithVersion = { ...payloadSettings, version: this.version };
    let newSettingsSaved;
    if (settingsId === undefined) newSettingsSaved = await userRespository.saveSettingsV2(this.type, settingsWithVersion);
    else newSettingsSaved = await userRespository.updateSettingsV2(this.type, settingsId, settingsWithVersion);
    return newSettingsSaved;
  };

  deleteSettings = async (settingsIdx = this.settingsStore.settingsIdx) => {
    const settings = this.settingsStore.settingsList[settingsIdx];
    if (!settings) throw new Error("Settings index out of bounds");
    const id = settings?._id?.id;
    // only delete in the server if settings are saved
    if (id !== undefined) {
      const response = await userRespository.deleteSettingsV2(id);
      if (response?.deleted !== true) throw new Error("Something went rong while deleting settings");
    }
    await this.loadSettingsList();
  };

  // Load settings from the server
  loadSettingsList = async (selectSettingsId = this.settingsStore.settings?._id?.id) => {
    let settingsList = [];
    try {
      settingsList = await userRespository.loadSettingsV2(this.type, this.version);
    } catch (error) {
      // This happens the first time, when users has no saved settings at all
      console.error("Error loading settings", error);
    }
    if (_isEmpty(settingsList))
      settingsList = [{ ...this.settingsStore.schema.defaultSettings, defaultConfiguration: true }];
    // Remove invalid settings
    settingsList = settingsList.filter((settings) => this._areSettingsValid(settings));
    // Select the settings with the provided id
    if (selectSettingsId !== undefined) {
      const idx = settingsList.findIndex((settings) => settings._id?.id === selectSettingsId);
      if (idx !== -1) {
        runInAction(() => {
          this.settingsStore.setSettingsList(settingsList);
          this.settingsStore.setSettingsIdx(idx);
        })
        return;
      }
    }

    // Select the default settings
    let idx = settingsList.findIndex((settings) => settings.defaultConfiguration);
    if (idx === -1) idx = 0;
    runInAction(() => {
      this.settingsStore.setSettingsList(settingsList);
      this.settingsStore.setSettingsIdx(idx);
    })
  };

  // Save new settings, i.e. they are no in the list of settings
  saveNewSettings = async (settings = this.settingsStore.schema.defaultSettings) => {
    const settingsSaved = await this._saveSettingsToServer(_omit(settings, "_id"));
    await this.loadSettingsList();
    return settingsSaved;
  };

  // Save settings to the server. These settings are already on the settingsList and will be updated.
  // If settings are not valid, throw an error
  saveSettings = async (settings = this.settingsStore.settings, settingsIdx = this.settingsStore.settingsIdx) => {
    const settingsToReplace = this.settingsStore.settingsList[settingsIdx];
    if (!settingsToReplace) throw new Error("Settings index out of bounds");
    if (settings._id?.id !== settingsToReplace._id?.id)
      throw new Error("You are trying to update settings with a different id");

    const settingsSaved = await this._saveSettingsToServer(settings);
    await this.loadSettingsList(this.settingsStore.settings?._id?.id || settingsSaved?._id?.id);
    return settingsSaved;
  };

  // Set the passed settings as the default ones to load,
  // also set any other settings to not be loaded as the deault ones
  saveSettingsAsDefault = async (settingsIdx = this.settingsStore.settingsIdx) => {
    if (settingsIdx >= this.settingsStore.settingsList.length) throw new Error("Settings index out of bounds");
    // Loop all settings to save the ones which need it
    for (let idx = 0; idx < this.settingsStore.settingsList.length; idx++) {
      const settings = this.settingsStore.settingsList[idx];
      const isDefault = idx === settingsIdx;
      if (settings.defaultConfiguration !== isDefault)
        await this._saveSettingsToServer({ ...settings, defaultConfiguration: isDefault });
    }
    await this.loadSettingsList();
  };
}
